














































import type { FooterOverlay } from '@/components/chrome/footer/Overlay.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { pushCta } from '@/inc/utils'

import CtaParallax from '@/components/cta/Parallax.vue'

export default defineComponent({
  name: 'CtaStories',
  components: { CtaParallax },
  props: {},

  setup() {
    const { chrome } = useGetters(['chrome'])
    const rootElRef = ref<HTMLElement | null>(null)
    const popupContentRef = ref<FooterOverlay | null>(null)

    const openPopup = () => {
      if (!rootElRef.value) {
        return
      }

      popupContentRef.value = chrome.value.ctas.stories.overlay

      // Track click
      pushCta(
        {
          ctaLabel: chrome.value.ctas.stories.title,
          clickText: chrome.value.ctas.stories.label,
          ctaType: 'journal',
        },
        rootElRef.value
      )
    }

    const closePopup = () => {
      popupContentRef.value = null
    }

    return {
      chrome,
      rootElRef,
      popupContentRef,
      openPopup,
      closePopup,
    }
  },
})
