






























































import type { Link, Picture } from '@/inc/types'

import gsap from 'gsap'
import { v4 as uuidv4 } from 'uuid'
import {
  PropType,
  defineComponent,
  ref,
  onBeforeUnmount,
} from '@vue/composition-api'

import { prefersReducedMotion } from '@/inc/utils'

import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'

gsap.registerPlugin(ScrollTrigger)

interface CtaParallax {
  title: string
  htmltext: string
  link: Link
  picture?: Picture
  items: {
    picture: Picture
    id: string
    from?: gsap.TweenVars
    to?: gsap.TweenVars
  }[]
}

export default defineComponent({
  name: 'CtaParallax',
  components: {},
  props: {
    content: {
      type: Object as PropType<CtaParallax>,
      required: true,
    },
  },

  setup(props, ctx) {
    const rootElRef = ref<HTMLElement | null>(null)
    const itemsElRef = ref<HTMLElement[]>([])
    const id = uuidv4()

    // Create timeline containing the parallax
    const initTimeline = () => {
      if (!rootElRef.value) {
        return
      }

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: rootElRef.value,
          scrub: 0.5,
          id,
          start: 'top bottom',
          end: 'bottom top',
        },
      })

      // Loop through items to add them to the timeline
      for (const item of props.content.items) {
        const from: gsap.TweenVars = {
          y: 100,
          ...item.from,
        }
        const to: gsap.TweenVars = {
          y: -100,
          ...item.to,
        }
        const el = itemsElRef.value.find(el => el.dataset.id === item.id)
        const ease = 'slow(0.3, 0.7, false)'

        el && tl.fromTo(el, from, { ...to, ease }, 0)
      }
    }

    // On appear, init ScrollTrigger
    const onAppear = () => {
      // Don't use ScrollTigger if the user prefers reduced motion
      if (prefersReducedMotion()) {
        return
      }

      initTimeline()
    }

    // Emit click event to parent
    const onClick = () => {
      ctx.emit('click')
    }

    onBeforeUnmount(() => {
      // Destroy ScrollTrigger instance
      ScrollTrigger?.getById(id)?.kill()
    })

    return { rootElRef, itemsElRef, onAppear, onClick }
  },
})
