var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chrome.ctas.stories && _vm.chrome.ctas.stories.title)?_c('div',{ref:"rootElRef",staticClass:"cta-stories wrapper p-m"},[_c('CtaParallax',{staticClass:"cta-stories__cta",attrs:{"content":Object.assign({}, _vm.chrome.ctas.stories,
      {picture: null,
      items: [
        {
          picture: _vm.chrome.ctas.stories.picture,
          id: 'test',
          from: {
            y: 30,
            rotate: -3,
          },
          to: {
            y: -70,
            rotate: -10,
          },
        } ]})},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('GAction',{staticClass:"cta-stories__button",attrs:{"content":{ label: _vm.chrome.ctas.stories.label, tag: 'button' },"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.openPopup.apply(null, arguments)}}})]},proxy:true}],null,false,3973463423)}),(_vm.popupContentRef)?_c('GPopup',{attrs:{"content":_vm.popupContentRef,"template":"footer"},on:{"popup:close":_vm.closePopup}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }